// eslint-disable-next-line
import React, { Component } from "react";
import "./home.css";
import logo from "../img/HEADERLOGOYARSI.png";
import logoIcon from "../img/logo 1.png"
import {Link} from "react-router-dom"
import shoppingBag from "../img/shopping bag.png";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
      
        <div className="header">
        <Link to='/'>
          <img className="header_logo_icon" src={logoIcon} alt="yarsi" />
          <img className="header_logo" src={logo} alt="Yarsi" />
          </Link>

          <Link to="/shopping"><span className="header_shop" style={{left:"40%"}}>Shop</span></Link>
          <span className="header_shop" style={{left:"48%"}}>Faq'S</span>
          <span className="header_shop" style={{left:"58%"}}>About</span>
          <span className="header_shop" style={{left:"68%"}}>Track Order</span>
          <span className="header_shop" style={{left:"80%"}}>|</span>
          <img className="header_bag" style={{left:"82%"}} src={shoppingBag} alt=""/>
         <Link to="/login"> <span className="header_shop" style={{left:"90%"}}>LOGIn/signUp</span></Link>
          
        </div>
       
      </>
    );
  }
}

export default Header;
