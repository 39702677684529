import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./component/home";
import { BrowserRouter } from "react-router-dom";
import Shop from "./component/Shop";
import Login from "./component/Login";
function Routes() {
  return (
    <>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/shopping" component={Shop}/>
        <Route exact path="/login" component={Login}/>
      </Switch>
      </BrowserRouter>
    </>
  );
}

export default Routes;
