import React, { Component } from 'react';
import Header from './Header';

export default class Login extends Component {
    render() {
        return (
            <div>
            <Header/>
            <h1>welocome to sign Sign Up</h1>
                
            </div>
        )
    }
}
