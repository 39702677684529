// eslint-disable-next-line
import React, { Component } from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import frontimg from "../img/hmgoepprod 1.png";
import "./home.css";
import Header from "./Header";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import logo from "../img/HEADERLOGOYARSI.png";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader:true
    };

   
  }

 componentDidMount (){
  window.location.href = "https://yarsi-customised-t-shirt-mug-caps.business.site"
 
 this.setState({
   loader:false
 })
  }
  render() {
    return (

      
      <>

        {/* <Header /> */}
        {this.loader ? <Loader 
          type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={3000}
        />: null}


        {/* <Carousel
          className="home_slide"
          showArrows={true}
          interval={2000}
          autoPlay={true}
        >
          <div class="home">
            <img className="home" src={frontimg} alt="" />
          </div>
          <div class="home">
            <img className="home" src={logo} alt="" />
          </div>
        </Carousel> */}
      </>
    );
  }
}

export default Home;
