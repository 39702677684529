import React, { Component } from 'react';
import Header from './Header';

export default class Shop extends Component {
    render() {
        return (
            <div>
            <Header/>
            <h1 style={{alignContent:"center"}}>  shopping page </h1>
                
            </div>
        )
    }
}
